<template>
  <v-list shaped dense>
    <span v-for="item in items" :key="item.title">
      <v-list-group v-if="item.isHeader" :key="item.title" :prepend-icon="item.icon">
        <v-list-item slot="activator">
          <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="subItem in item.subItems"
          :key="subItem.title"
          @click.stop="changeRoute(subItem.route)" style="margin-left: 20px;">
          <v-list-item-action>
            <v-icon color="#A0A0A0">{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{subItem.title}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item v-else @click.stop="changeRoute(item.route)">
        <v-list-item-action>
          <v-icon color="#A0A0A0">{{item.icon}}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{item.title}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </span>
  </v-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { role } from '../../helpers/enums'
import { userInfo } from '../../mixins'

export default {
  name: 'MenuList',
  data: () => ({
    items: []
  }),
  computed: {
    ...mapGetters('snackbarModule', ['snackbar'])
  },
  methods: {
    ...mapActions('snackbarModule', ['hideMessage']),
    changeRoute (route) {
      this.$router.push({ name: route }).catch(()=>{})
    },
    loadMenubyRole () {
      const menu = [
        {
          allowedRoles: [role.ADMIN, role.VIEWER, role.GENERALMANAGER, role.ISSUER],
          title: 'Home',
          icon: 'mdi-home',
          route: 'home',
          isHeader: false
        },
        {
          allowedRoles: [role.ADMIN],
          title: 'Catalogos',
          isHeader: true,
          icon: 'mdi-label',
          subItems: [
            {
              allowedRoles: [role.ADMIN],
              title: 'Usuarios',
              icon: 'mdi-format-list-bulleted-type',
              route: 'users',
              isHeader: false
            },
            {
              allowedRoles: [role.ADMIN],
              title: 'Camiones',
              icon: 'mdi-format-list-bulleted-type',
              route: 'trucks',
              isHeader: false
            }
          ]
        },
        {
          allowedRoles: [role.ADMIN],
          title: 'Captura',
          isHeader: true,
          icon: 'mdi-label',
          subItems: [
            {
              allowedRoles: [role.ADMIN],
              title: 'diesel',
              icon: 'mdi-format-list-bulleted-type',
              route: 'disel',
              isHeader: false
            }
          ]
        },
        // {
        //   allowedRoles: [role.ADMIN],
        //   title: 'Reportes',
        //   isHeader: true,
        //   icon: 'mdi-label',
        //   subItems: [
        //     {
        //       allowedRoles: [role.ADMIN],
        //       title: 'Reporte de disel',
        //       icon: 'mdi-format-list-bulleted-type',
        //       route: 'diselReport',
        //       isHeader: false
        //     }
        //   ]
        // },
        {
          allowedRoles: [role.ADMIN],
          title: 'Perfil',
          icon: 'mdi-account',
          route: 'profile'
        },
        {
          allowedRoles: [role.ADMIN],
          title: 'Salir',
          icon: 'mdi-logout',
          route: 'logout'
        }
      ]
      for (const option of menu) {
        if (option.allowedRoles.includes(this.userInfo.idUserType)) {
          if (Object.prototype.hasOwnProperty.call(option, 'subItems')) {
            const subOptions = Object.assign([], option.subItems)
            option.subItems = []
            for (const subOption of subOptions) {
              if (subOption.allowedRoles.includes(this.userInfo.idUserType)) {
                option.subItems.push(subOption)
              }
            }
          }
          this.items.push(option)
        }
      }
    }
  },
  mounted () {
    this.loadMenubyRole()
  },
  mixins: [userInfo]
}
</script>
<style>
</style>
