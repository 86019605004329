import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import HomePage from '../views/HomePage.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import NotAllowedPage from '../views/NotAllowedPage.vue'
import authService from '../helpers/authService'
import store from '../store'
import { role } from '../helpers/enums'

Vue.use(VueRouter)

var authenticationCheck = (to, from, next) => {
  if (to.meta.allowAnonymous) {
    next()
    return
  }

  authService.getSession().then((r) => {
    const userInfo = store.getters['loginModule/userInfo']
    if (!userInfo || !userInfo.isAuthenticated) {
      next({ path: 'notAllowed' })
      return
    }

    if (to.meta.roles) {
      if (to.meta.roles.indexOf(userInfo.idUserType) === -1) {
        next({ path: 'notFound' })
      }
    }
    next()
  }).catch((e) => {
    next({ path: 'notAllowed' })
  })
}

const routes = [
  { path: '/', name: 'login', component: Login, meta: { allowAnonymous: true } },
  { path: '/logout', name: 'logout', component: Logout, meta: { allowAnonymous: true } },
  { path: '/home', name: 'home', component: HomePage, meta: { roles: [role.ADMIN] } },
  { path: '/admin/profile', name: 'profile', component: () => import('../views/Profile.vue'), meta: { roles: [role.ADMIN] } },
  { path: '/admin/users', name: 'users', component: () => import('../views/UsersPage.vue'), meta: { roles: [role.ADMIN] } },
  { path: '/admin/trucks', name: 'trucks', component: () => import('../views/TrucksPage.vue'), meta: { roles: [role.ADMIN] } },

  { path: '/capture/disel', name: 'disel', component: () => import('../views/DiselPage.vue'), meta: { roles: [role.ADMIN] } },

  { path: '/notAllowed', name: 'notAllowed', component: NotAllowedPage, meta: { allowAnonymous: true } },
  { path: '*', name: 'notFound', component: NotFoundPage, meta: { allowAnonymous: true } }
]

const router = new VueRouter({
  routes
})

router.beforeEach(authenticationCheck)

export default router